<template>
	<div id="tutoredin-forgot">
		<div class="warp">
			<el-form ref="forgotForm" :model="form" :rules="rules" label-width="0px">
				<div class="title" v-if="is_reset==0">{{ $t('forgot.forgot-password') }}</div>
				<div class="title" v-else>{{ $t('forgot.reset-your-password') }}</div>
				<div class="subTitle" v-if="is_reset==0">{{ $t('forgot.reset-password') }}</div>
				<div class="note" v-if="is_reset==0">{{ $t('forgot.note1') }}</div>
				<div class="note" v-if="is_reset==0">{{ $t('forgot.note2') }}</div>
				<div class="note" v-if="is_reset==1">{{ $t('forgot.note3') }}</div>
				<div class="note">
					<el-form-item prop="email">
						<el-input v-model="form.email" size="large" placeholder="Email*"></el-input>
					</el-form-item>
					<el-form-item prop="password" v-if="is_reset==1">
						<el-input v-model="form.password" type="password" show-password size="large" :placeholder="$t('forgot.new-password')"></el-input>
					</el-form-item>
				</div>
				<div style="margin-top: 2rem;">
		            <a @click="onSendResetPassword" v-if="is_reset==0" class="link">{{ $t('forgot.send-reset') }}</a>
		            <a @click="onResetPassword" v-else class="link">{{ $t('forgot.reset-password') }}</a>
		        </div>
		        <div style="margin-top: 1rem;">
		        	<el-link @click="$router.push('/login')" type="primary">{{ $t('router.login') }}</el-link>
		        </div>
		    </el-form>
		</div>
	</div>
</template>
<script>
import AuthApi from '@/api/auth'

export default {
  data() {
    return {
    	token: '',
    	is_reset: 0,
    	form: {
    		email: '',
    		password: ''
    	},
    	rules: {
    		email: [
				{
				    required: true,
				    message: "邮箱地址必须填写",
				    trigger: 'blur'
				},
				{
				    type: 'email',
				    message: 'Please input correct email address',
				    trigger: ['blur', 'change'],
				},
			],
			password: [
				{
					required: true,
					message: "密码必须填写",
					trigger: 'blur'
				}, 
				{
					min: 6,
					max: 16,
					message: "登录密码的长度在 6 到 16 位",
					trigger: 'blur'
				}
			]
    	}
    }
  },
  created() {
  	this.token = this.$route.query.token || '';
  	if(!!this.token) {
  		this.onForgotVerify();
  	}
  },
  methods: {
  	onForgotVerify() {
		AuthApi.forgot_verify({_tk: this.token}).then(res => {
			if(!!res.data) {
			this.form.email = res.data;
			this.is_reset = 1;
			}
		})
  	},
  	onSendResetPassword() {
  		this.$refs.forgotForm.validate((valid) => {
			if(!valid) {
				return false;
			}
			AuthApi.forgot(this.form).then(res => {
				this.$message.success("邮件已发送，请登录邮箱进行处理");
			})
		});		
  	},
  	onResetPassword() {
  		this.$refs.forgotForm.validate((valid, value) => {
			if(!valid) {
				return false;
			}
			let that = this;
			let params = this.form;
			params._tk = this.token;
			AuthApi.forgot_verify(params).then(res => {
				this.$message.success("If the password is changed successfully, you will be redirected to the login page.");
				setTimeout(function(){
					that.$router.push({path: '/login'});
				}, 3000);
			})
		});
  	}
  },
}
</script>
<style lang="less">
#tutoredin-forgot {
	text-align: center;
	margin: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
    color: #212529;
    box-sizing: border-box;
    background-color: #fff;

    a {
    	cursor: pointer;
    }

    .warp {
    	display: inline-block;
    	margin-top: 2rem;
    	width: 600px;
    	padding: 3rem 2rem;    
    	margin-bottom: 3rem!important;
    	box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
    	border-radius: .25rem!important;
    	background-color: #fff!important;

    	.title {
    		font-size: 22px; 
    		font-weight: bold;
    		color: var(--el-color-primary);
    	}

    	.subTitle {
    		font-size: 18px; 
    		font-weight: bold;
    		color: var(--el-color-info);
    		margin-top: 6px;
    	}

    	.note {
    		margin-top: 1rem !important;
    		text-align: left;
    		color: #555;
    	}

    	.link {
    		text-decoration: none;
    		padding: 12px 65px;
    		border-radius: 5px;
    		background: #f56c6c;
    		color: #FFF;
    		font-size: 15px;
    	}
    }

}

</style>